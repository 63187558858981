"use client";

import Image, { StaticImageData } from "next/image";
import hajara from "/public/images/hajara.png";
import wahab from "/public/images/wahab.png";
import { Carousel } from "antd";

const REVIEWS = [
  {
    content:"I was initially confused about which course would suit my personality and interests. Thanks to GingerEdu, I was able to find the best fit for me.",
    name: "Hajara Yusuf",
    job: "Student",
    image: hajara,
  },
  {
    content:
      "Thanks to GingerEdu, I was able to choose a career path that aligned with my passions and strengths. I highly recommend GingerEdu to anyone looking to succeed.",
    name: "Lanre Aremu",
    job: "Data scientist",
    image: wahab,
  },
];

export default function Reviews() {
  return (
    <section className="mt-20 flex flex-col items-center w-full">
      <p className="text-2xl lg:text-4xl mb-8 lg:mb-16 text-center">What people are saying about us</p>

      <div className="w-full">
        <Carousel autoplay autoplaySpeed={2000} effect="fade">
          {REVIEWS.map((review, idx) => (
            <Review {...review} key={idx} />
          ))}
        </Carousel>
      </div>
    </section>
  );
}

interface Review {
  content: string;
  name: string;
  image: StaticImageData;
  job: string;
}

function Review({ content, name, image, job }: Review) {
  return (
    <div className="flex flex-col-reverse lg:flex-row bg-neutral-600 rounded-3xl w-full justify-between items-start lg:items-center p-8 lg:p-20 gap-4">
      <div className="flex flex-col max-w-[550px] gap-4">
        <p className="text-base lg:text-3xl">{content}</p>
        <p className="mt-4 text-sm lg:text-lg">
          {name} - {job}
        </p>
      </div>
      <Image
        src={image}
        alt="reviewer image"
        className="rounded lg:rounded-3xl object-contain w-[200px] h-[200px] lg:w-[300px] lg:h-[323px]"
      />
    </div>
  );
}
