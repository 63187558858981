import type { ThemeConfig } from "antd";

const antdTheme: ThemeConfig = {
  token: {
    controlHeightLG: 48,
    fontFamily: 'var(--font-inter)',
  },
  components: {
    Menu: {
      horizontalItemSelectedColor: "#03846E",
    },
  },
};

export default antdTheme;
