"use client";

import { signOut } from "next-auth/react";
import { Layout, Menu, Button, Avatar, Space, Dropdown, MenuProps } from "antd";
import {
  DownOutlined,
  WechatOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import Link from "next/link";
import Image from "next/image";
import { images } from "@/themes";
import { usePathname, useRouter } from "next/navigation";
import { useEffect, useState } from "react";
import { CloseOutlined, MenuOutlined } from "@ant-design/icons";

const { Header } = Layout;

const mainMenuItems = [
  // {
  //   key: "/careers",
  //   label: `Careers`,
  // },
  {
    key: "/podcasts",
    label: `Podcasts`,
  },
  {
    key: "/blog",
    label: `Blog`,
  },
  {
    key: "/chat",
    label: `Career Chatbot`,
  },
];

const mobileMenuItems = [
  // {
  //   key: "/careers",
  //   label: `Careers`,
  // },
  {
    key: "/podcasts",
    label: `Podcasts`,
  },
  {
    key: "/blog",
    label: `Blog`,
  },
  {
    key: "/chat",
    label: `Career Chatbot`,
  },
];

type NavbarProps = {
  session: any;
};

export const Navbar = ({ session }: NavbarProps) => {
  const router = useRouter();
  const pathname = usePathname();
  const userIntitials = session
    ? session.username.charAt(0).toUpperCase()
    : null;

  const profileItems: MenuProps["items"] = [
    {
      key: "chat",
      label: "Career Chatbot",
      icon: <WechatOutlined />,
      onClick: () => router.push("/chat"),
    },
    {
      key: "/signout",
      label: "Sign Out",
      icon: <LogoutOutlined />,
      onClick: () => signOut({ callbackUrl: "/login" }),
    },
  ];

  const updatedMobileMenuItems = [
    ...mobileMenuItems,
    ...(session
      ? [
          {
            key: "",
            label: (
              <Link
                href="/sign-out"
                className="text-xl text-black mr-8"
                onClick={(e) => {
                  e.preventDefault();
                  signOut();
                }}
              >
                Sign Out
              </Link>
            ),
          },
        ]
      : [
          {
            key: "/sign-up",
            label: (
              <Link href="/sign-up" className="text-xl text-black mr-8">
                Sign Up
              </Link>
            ),
          },
          {
            key: "/login",
            label: (
              <Link
                href="/login"
                className="text-xl text-white bg-ginger-green px-8 py-3 rounded-xl"
              >
                Login
              </Link>
            ),
          },
        ]),
  ];

  const onMenuClick = (item: any) => {
    router.push(`${item.key}`);
  };

  const selectedKeys = pathname != null ? [pathname] : [];

  const [showMobileMenu, SetShowMobileMenu] = useState(false);
  const toggleMobileMenu = () => {
    SetShowMobileMenu(!showMobileMenu);
  };

  // Close mobile menu when path changes, because it is keeping the state when a new link is clicked
  useEffect(() => {
    SetShowMobileMenu(false);
  }, [pathname]);

  return (
    <Header className="fixed top-0 z-50 w-full flex items-center justify-between px-4 md:px-14 h-[100px] bg-white border-b border-solid border-gray-300 ">
      <Link href="/">
        <Image
          src={images.gingerlogo}
          width={100}
          height={30}
          className="w-auto h-auto"
          alt="GingerEdu Logo"
        />
      </Link>

      {/* Hamburger Icon for Mobile */}
      <Button
        className="md:hidden text-2xl flex items-center"
        type="text"
        onClick={toggleMobileMenu}
      >
        {showMobileMenu ? (
          <CloseOutlined className="text-ginger-green" />
        ) : (
          <MenuOutlined className="text-ginger-green" />
        )}
      </Button>

      {/* Collapsible Menu for Mobile */}
      {showMobileMenu && (
        <div className="md:hidden absolute top-full right-0 w-1/2">
          <Menu
            theme="light"
            mode="inline"
            items={updatedMobileMenuItems}
            selectedKeys={selectedKeys}
            className="text-xl pb-4"
            onSelect={onMenuClick}
          />
        </div>
      )}

      {/* Menu for Medium and Large screen */}
      <div className="hidden md:flex items-center flex-grow">
        <Menu
          theme="light"
          mode="horizontal"
          items={mainMenuItems}
          selectedKeys={selectedKeys}
          className="flex-grow flex justify-center text-xl border-none"
          onSelect={onMenuClick}
        />
        <div className="flex items-center">
          {session ? (
            <Dropdown menu={{ items: profileItems }}>
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  <Avatar>{userIntitials}</Avatar>
                  <DownOutlined className="text-slate-800	" />
                </Space>
              </a>
            </Dropdown>
          ) : (
            <>
              <Link href="/sign-up" className="text-xl text-black mr-8">
                Sign Up
              </Link>
              <Link
                href="/login"
                className="text-xl text-white bg-ginger-green px-8 py-3 rounded-xl"
              >
                Login
              </Link>
            </>
          )}
        </div>
      </div>
    </Header>
  );
};
