"use client";

import { Input } from 'antd';
import { useRouter } from 'next/navigation';
import { Typewriter } from 'react-simple-typewriter'

const { Search } = Input;
export default function Prospects() {
  const router = useRouter()

  const onSearch = (value:string, event :any) => {
    event.preventDefault();
    router.push(`/blog?search=${value}`)
  };

  return (
    <section className="mt-16 flex flex-col items-center gap-4 sm:px-8">
      <h1 className="text-2xl md:text-4xl text-center">What are my prospect as a {' '}
        <Typewriter
          words={[
            'Tech-bro ?',
            'Doctor ?',
            'Lawyer ?',
            'Microbiologist ?',
            'Materials Engineer ?',
            'Journalist ?'
          ]}
          loop={false}
          cursor
          typeSpeed={100}
          deleteSpeed={100}
        />
      </h1>

      <form className="w-full max-w-[700px] pt-8">
        <Search
          size="large"
          placeholder="Search by career expertise"
          onSearch={onSearch}
        />
      </form>
    </section>
  );
}
