export const images = {
  gingerlogo: "/brand/Gingerlogo.svg",
  arrowRightCircle: "/icons/arrow-right-circle.svg",
  brandPeople: "/images/brandPeople.png",
  searchIcon: "/icons/search.svg",
  orientation: "/images/orientation.png",
  mentorship: "/images/mentorship.png",
  guidingParents: "/images/guidingParents.png",
  AI: "/icons/ai.svg",
  breifCase: "/icons/briefcase.svg",
  podcast: "icons/podcast.svg",
  resources: "/icons/podcast-1.svg",
  headerPhoto: "/images/banner.jpeg",
  graduatePhoto: "/images/graduate.png",
};
