"use client";

import { getURL } from "@/utils/util";
import { Form, Input, notification, Button } from "antd";
import { useState } from "react";


type NotificationType = 'success' | 'info' | 'warning' | 'error';
const openNotificationWithIcon = (type: NotificationType, description: string, title: string) => {
  notification[type]({
    message: title,
    description: description,
  });
};

export default function Newsletter() {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [form] = Form.useForm();

  const onFinish = async (payload:any) => {
    setIsSubmitting(true);

    const baseURL = getURL();

    const res = await fetch(`${baseURL}blog/subscribe`, {
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    }).catch((err) => {
        openNotificationWithIcon('error', 'Subscription failed.', 'Subscription failed');
    });

    if (res && res.status === 201) {
      openNotificationWithIcon(
        'success', 
        'Thank you for subscribing, you will receive our next update in your inbox.',
        'Subscription successful',
      );
      form.resetFields();
    }
    else {
      const response = await res?.json();
      const errorMessage = response?.errors?.email?.length > 0
      ? response.errors.email.join('. ')
      : 'Subscription failed, please try again later';

      openNotificationWithIcon('error', errorMessage, 'Subscription failed');
    }
    setIsSubmitting(false);
  };


  return (
    <section className="flex flex-col gap-4 mt-10 md:mt-16">
      <h1 className="text-2xl md:text-4xl text-center">Get our next insightful update in your Inbox</h1>
      <div className="bg-neutral-600 rounded-lg p-4 md:p-16 mt-2">
        <Form
          form={form}
          scrollToFirstError
          onFinish={onFinish}
          layout="horizontal"
          autoComplete="off"
          disabled={isSubmitting}
        >
          <div className="flex flex-col md:flex-row gap-3 items-stretch md:items-center sm:pt-8">
            <Form.Item
              name="first_name"
              validateTrigger="onBlur"
              rules={[{ max: 250 }]}
            >
              <Input 
                placeholder="First Name" 
                required
                size="large" />
            </Form.Item>

            <Form.Item
              name="last_name"
              validateTrigger="onBlur"
              rules={[{ max: 250 }]}
            >
              <Input placeholder="Last Name" size="large" />
            </Form.Item>

            <Form.Item
              name="email"
              validateTrigger="onBlur"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "Please input your E-mail!",
                },
              ]}
            >
              <Input placeholder="Email address" type="email" size="large" />
            </Form.Item>
            
            <Button
              className="bg-ginger-green mb-6"
              type="primary"
              htmlType="submit"
              size="large"
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              Subscribe {isSubmitting && "..."}
            </Button>
          </div>
        </Form>
      </div>
    </section>
  );
}
