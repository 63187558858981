"use client";

import { useRouter } from 'next/navigation';
import { Button } from "antd";
import Image from "next/image";
import { Typewriter } from "react-simple-typewriter";

import { images } from "@/themes";

export default function Banner() {
  const router = useRouter();

  return (
    <section className="flex flex-col-reverse md:flex-row md:justify-between gap-14">
      <div className="md:pt-14 md:flex-1">
        <p className="font-bold rounded-full bg-neutral-200 border-neutral-400 px-4 py-1 border w-fit">
          The peak of Educational Innovation
        </p>
        <p className="text-ginger-green font-bold pt-5 text-3xl lg:text-6xl">
          Be informed about the major you
          <Typewriter
            words={[" intend to study.", "'re studying."]}
            loop={false}
            cursor
            typeSpeed={100}
            deleteSpeed={100}
          />
        </p>
        <p className="pt-8 text-sm lg:text-lg opacity-80 text-gray-100">
          Join the GingerEdu community for personalized guidance and
          comprehensive resources to help you make informed decisions about your
          education and career path. Discover your path to success today.
        </p>
        <Button
          className="flex items-center bg-ginger-green text-white mt-8"
          size="large"
          onClick={() => router.push('/sign-up')}
        >
          Join Community
          <Image
            className="ml-2"
            src={images.arrowRightCircle}
            width={20}
            height={20}
            alt="arrow right circle"
          />
        </Button>
      </div>
      <div className="md:flex-1">
        <Image
          className="!static"
          layout="fill"
          objectFit="cover"
          src={images.headerPhoto}
          alt="Landing page header picture"
        />
      </div>
    </section>
  );
}
