"use client";

import Link from "next/link";
import { Layout } from "antd";
import Image from "next/image";
import { images } from "@/themes";

const { Footer } = Layout;

export default function FooterComponent() {
  return (
    <Footer className="p-8 mt-8 bg-neutral-100">
      <div className="flex flex-row gap-3 items-end">
        <Link href="/">
          <Image
            src={images.gingerlogo}
            width={100}
            height={30}
            alt="GingerEdu Logo"
          />
        </Link>
        <Link
          href="https://www.instagram.com/ginger_education/"
          target="_blank"
          className="text-gray-100"
          rel="noopener noreferrer"
        >
          Instragram
        </Link>
        <Link
          href="https://www.linkedin.com/company/gingeredu/"
          target="_blank"
          className="text-gray-100 flex items-center"
          rel="noopener noreferrer"
        >
          LinkedIn
        </Link>
      </div>
      <hr className="mt-3 border-ginger-gray-50" />
      <p className="pt-3 text-gray-200">
        © {new Date().getFullYear()} GINGEREDU All Rights Reserved.
      </p>
    </Footer>
  );
}
